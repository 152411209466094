<template>
    <loader v-bind="{ loading }">
        <form>
            <columns>
                <column>
                    <text-input 
                        required 
                        classes="is-medium is-rounded" 
                        :error="$root.errors.name"
                        v-model="contact.name">
                        Name
                    </text-input>
                </column>
                <column>
                    <text-input 
                        required 
                        classes="is-medium is-rounded" 
                        :error="$root.errors.surname"
                        v-model="contact.surname">
                        Last Name
                    </text-input>
                </column>
            </columns>
            <columns>
                <column>
                    <text-input required classes="is-medium is-rounded" :error="$root.errors.email" v-model="contact.email">
                        Email Address
                    </text-input>
                </column>
                <column>
                    <text-input classes="is-medium is-rounded" v-model="contact.mobile" :error="$root.errors.mobile">
                        Mobile <small>(Optional)</small>
                    </text-input>
                </column>
                <column>
                    <text-input classes="is-medium is-rounded" v-model="contact.position" :error="$root.errors.position">
                        Position <small>(Optional)</small>
                    </text-input>
                </column>
            </columns>

            <columns>
                <column>
                    <data-selector
                        searchable
                        multiple
                        required
                        :items="locations"
                        v-model="contact.locations"
                        value-key="uuid"
                        label-key="name"
                        classes="is-medium is-rounded"
                        tag-class="is-medium is-rounded"
                        delete-button-class="is-medium is-rounded is-danger">
                        Locations
                    </data-selector>
                </column>
            </columns>

            <columns>
                <column>
                    <h4 class="title is-4">Notification Preferences</h4>
                </column>
            </columns>

            <columns>
                <column class="is-narrow">
                    <switch-input v-model="contact.notification_preferences.work_order_opened">
                        New Work Order Opened
                    </switch-input>
                </column>
                <column class="is-narrow">
                    <switch-input v-model="contact.notification_preferences.work_order_completion">
                        Work Order Completion Report
                    </switch-input>
                </column>
                <column class="is-narrow">
                    <switch-input v-model="contact.notification_preferences.checklist_completion">
                        Checklist Completion Report
                    </switch-input>
                </column>
            </columns>

            <columns>
                <column>
                    <submit-button :working="creating_contact" @submit="createContact" class="is-rounded">
                        Create Contact
                    </submit-button>
                </column>
            </columns>
        </form>
    </loader>
</template>
<script>
import { customer as backend } from '@/api'
import { mapGetters } from 'vuex'

export default {

    data: () => ({
        loading: true,
        creating_contact: false,
        locations: [],
        contact: {
            name: '',
            surname: '',
            email: '',
            mobile: '',
            position: '',
            locations: [],
            notification_preferences: {
                checklist_completion: false,
                work_order_completion: false,
                work_order_opened: false
            }
        }
    }),

    beforeCreate() {
        backend.loadCustomerLocationsList(this.$route.params.customer, ({data}) => {
            this.locations = data
            this.loading = false
        }, () => this.loading = false)
    },

    methods: {
        createContact() {
            this.creating_contact = true
            const contact = {...this.contact}
            contact.locations = contact.locations.map(location => location.uuid)
            backend.createContact({
                uuid: this.customer.uuid,
                ...contact
            }, () => {
                this.$router.back()
            }, () => this.creating_contact = false)
        }
    },

    computed: {
        ...mapGetters('customer', [
            'customer'
        ])
    },

}
</script>